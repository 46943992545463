import { render, staticRenderFns } from "./index.vue?vue&type=template&id=434a1030&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingpageMain: require('C:/dev/nuxtProjects/talb/components/LandingpageMain.vue').default,APFormHomeMain: require('C:/dev/nuxtProjects/talb/components/AP/FormHomeMain.vue').default,APFormTestimonials: require('C:/dev/nuxtProjects/talb/components/AP/FormTestimonials.vue').default})
